.tp-caption[data-typewriter]:after {
	
	position: absolute;
	
}

.tp-caption[data-typewriter-blinking]:after {
	
	position: static;
	
}

@keyframes tp-typewriter {
	
	from {visibility: visible}
	to {visibility: hidden}
	
}